@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
/*@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';*/
/*@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";*/

@font-face {
    font-family: "AktivGrotesk";
    src: url("./fonts/AktivGrotesk.woff2");
    font-weight: normal;
}
